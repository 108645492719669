<template :key="$route.fullPath">
    <div class="wraper" >
        <Header :isShow="isShow" />
            <!-- 轮播图 -->
            <div class="banner" >
                <img :src="imgUrl + banner" alt="" />
            </div>
            <!-- 电脑端滑块 -->
            <div class="slider_box" ref="header">
                <div class="slider max_big_box">
                    <div class="swiper-container max_small_box" v-if="sliderList">
                        <div class="swiper-wrapper" >
                            <router-link :to="`/industry/${item.id}${item.page_id}/${item.second_navigation_id}/${index}`" :class="sliderActive == item.id ? 'swiper-slide swiper-slide_active' : 'swiper-slide' " v-for="(item, index) in sliderList" :key="index">
                                <template  v-if="sliderActive == item.id">
                                    <img class="list_img" :src="imgUrl + item.icon_select_image" alt="">
                                    <img  class="list_img select_img" :src="imgUrl + item.icon_select_image" alt="">
                                </template>
                                <!-- <img  class="list_img" :src="imgUrl + item.icon_select_image" alt=""> -->
                                <template  v-else>
                                    <img  class="list_img" :src="imgUrl + item.icon_image" alt="">
                                    <img class="list_img select_img" :src="imgUrl + item.icon_select_image" alt="">
                                </template>
                                <p class="list_title">{{item.name}}</p>
                                <div class="right_line"></div>
                            </router-link>
                        </div>
                        <!-- Add Arrows -->
                    </div>
                    <div class="swiper-button-next swiper-button-black"></div>
                    <div class="swiper-button-prev swiper-button-black"></div>
                </div>
            </div>
            <!-- 手机端滑块 -->
            <div class="slider_box_two" v-if="third_id!='43' && third_id!='44'">
                <div class="slider max_big_box">
                    <div class="swiper-container_two max_small_box" v-if="sliderList">
                        <div class="swiper-wrapper">
                            <router-link :to="`/industry/${item.id}${item.page_id}/${item.second_navigation_id}/${index}`" :class="sliderActive == item.id ? 'swiper-slide swiper-slide_active' : 'swiper-slide' " v-for="(item, index) in sliderList" :key="index">
                                <img v-if="sliderActive == item.id" class="list_img" :src="imgUrl + item.icon_select_image" alt="">
                                <img v-else class="list_img" :src="imgUrl + item.icon_image" alt="">
                                
                                <p class="list_title">{{item.name}}</p>
                                <div class="right_line"></div>
                            </router-link>
                        </div>
                        <!-- Add Arrows -->
                    </div>
                    <div class="swiper-button-next swiper-button-black"></div>
                    <div class="swiper-button-prev swiper-button-black"></div>
                </div>
            </div>
            <!-- 内容 -->

            <div class="content_box" v-if="third_id!='43' && third_id!='44'">
                <div class="content max_small_box">
                    <div class="img" v-if="serviceDetail" data-aos="fade-right">
                        <img v-if="serviceDetail.image" :src="imgUrl + serviceDetail.image" />
                    </div>
                    <div class="text" v-if="serviceDetail" data-aos="fade-left">
                        <p class="title">{{serviceDetail.name}}</p>
                        <!-- <p class="desc">{{serviceDetail.intro}}</p> -->
                        <div v-if="serviceDetail.detail" class="desc" v-html="serviceDetail.detail"></div>
                        <div class="" v-if="second_id == 2 && serviceDetail.id == 17">
                            <router-link class="online" :to="`/industry_list/1/0`">查看更多检测服务</router-link >
                            <p style="margin-left:30px;" class="online" @click="changeModal">在线咨询</p>
                        </div>
                        <p v-else class="online" id="kefu_button" @click="changeModal">在线咨询</p>
                    </div>
                </div>
                <template v-if="serviceDetail">
                    <template v-if="serviceDetail.content == '' || serviceDetail.content == null"></template>
                    <div class="detail_title max_small_box" v-else>
                        <p :class="{'active': title_index==1}" @click="changeContent(1)">服务介绍</p>
                        <p v-if="table_data.length!=0" :class="{'active': title_index==2}" @click="changeContent(2)">产品清单</p>
                    </div>
                </template>
                <template v-if="serviceDetail">
                    <template v-if="serviceDetail&&title_index==1">
                        <div class="detail_box" v-if="serviceDetail.content != ''">
                            <div class="detail max_small_box">
                                <div class="list" v-for="(item, index) in serviceDetail.content" :key="index">
                                    <p class="title">{{item.title}}</p>
                                    <div class="desc" v-html="item.desc"></div>
                                </div> 
                            </div>
                        </div>
                    </template>
                </template>

                <!-- 电脑端 -->
                <template v-if="serviceDetail">
                    <template v-if="serviceDetail&&title_index==2">
                        <a-table class="max_small_box" style="margin-top: 50px;scrollbar-width: 66px;" :columns="columnsTwo" :data-source="table_data" :pagination="false" rowKey="id" :scroll="{ x: 2000}">
                            <template v-slot:num="slotProps">{{
                                slotProps.index + 1
                            }}</template>
                        </a-table>
                    </template>
                </template>
            </div>
            <!-- 服务 -->
            <div class="service_box" v-if="serviceDetail">
                <template v-if=" serviceDetail.service">
                    <div class="service max_small_box" v-if=" serviceDetail.service.length != 0">
                        <div class="top">
                            <p class="title">服务项目</p>
                            <p class="line"></p>
                        </div>
                        <div class="under" v-if="serviceDetail">
                            <div class="service-swiper-container">
                                <div class="swiper-slide"  v-for="(item, index) in serviceDetail.service" :key="index" data-aos="fade-up" :data-aos-delay="(index+1)*200">
                                    <router-link :to="`/serviceDetail/${item.id}`">
                                        <div class="img">
                                            <img :src="imgUrl + item.image" alt="" />
                                        </div>
                                        <p class="title">{{item.name}}</p>
                                        <p class="desc">{{item.desc}}</p>
                                        <p class="more">查看更多</p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <!-- 手机端 -->
            <div class="third_nav max_small_box" v-if="third_id=='43' || third_id=='44'">
                <div class="text" v-for="(item, index) in thirdList" :key="index" @click="handleChangeThird(item.id, index)">
                    <p :class="thirdIndex == index ? 'active' :''">{{item.name}}</p>
                </div>
            </div>
            <div v-if="third_id=='43'">
                <a-table class="max_small_box" style="margin-top: 20px;scrollbar-width: 66px;" :columns="columnsOne" :data-source="data" :pagination="false" rowKey="id" :scroll="{ x: 1700}">
                    <template v-slot:num="slotProps">{{
                                slotProps.index + 1
                            }}</template>
                </a-table>
                <div class="pagination max_small_box">
                <a-pagination :default-current="pageOne" :total="last_page" @change="handleChangePage" />
            </div>
            </div>
            

            <div  v-if="third_id=='44'">
                <a-table class="max_small_box" style="margin-top: 20px;scrollbar-width: 66px;" :columns="columnsTwo" :data-source="data" :pagination="false" rowKey="id" :scroll="{ x: 1700}">
                    <template v-slot:num="slotProps">{{
                                slotProps.index + 1
                            }}</template>
                </a-table>
                <div class="pagination max_small_box">
                <a-pagination :default-current="pageTwo" :total="last_page" @change="handleChangePage" />
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Swiper, {   
  A11y,
  Autoplay,
  Controller,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  HashNavigation,
  History,
  Keyboard,
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs,
  Virtual,
  Zoom, } from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//滑块处理函数
const sliderEffect = (route) => {
    //选中的index
    const sliderActive = ref(0)
    // 数据列表
    const sliderList = ref()

    const third_id = ref()
    //详情
    const serviceDetail = ref()

    // 小标题index
    const title_index = ref(1)

    
    // 三级导航
    const thirdList = ref()
    //一的页数
    const pageOne = ref(1)
    //二的页数
    const pageTwo = ref(1)

    //一的总页数
    const last_page = ref()
    //选中的index
    const thirdIndex = ref(0)



    const columnsOne = [
        // {
        //     title: '质量技术服务大类代码',
        //     dataIndex: 'type_code',
        //     key: 'type_code',
        //     scopedSlots: { customRender: 'type_code' },
        //     align:'center',
        //     width:'110px',

        // },
        // {
        //     title: '质量技术服务大类名称',
        //     dataIndex: 'type_name',
        //     key: 'type_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '质量技术服务代码',
        //     dataIndex: 'quality_code',
        //     key: 'quality_code',
        //     align:'center',
        //     width:'100px',
        // },
        // {
        //     title: '质量技术服务名称',
        //     dataIndex: 'quality_name',
        //     key: 'quality_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '检测服务代码',
        //     dataIndex: 'service_code',
        //     key: 'service_code',
        //     align:'center',
        //     width:'80px',
        // },
        {
            title: "序号",
            slots: { customRender: "num" },
            align:'center',
            width:'100px',
        },
        {
            title: '检测服务名称',
            dataIndex: 'name',
            key: 'name',
            align:'center',
            width:'150px',
        },
        {
            title: '产品编码',
            dataIndex: 'product_id',
            key: 'product_id',
            align:'center',
            width:'130px',
        },
        {
            title: '行业分类',
            dataIndex: 'industry_type',
            key: 'industry_type',
            align:'center',
            width:'110px',
        },
        {
            title: '产品类别',
            dataIndex: 'product_type',
            key: 'product_type',
            align:'center',
            width:'90px',
        },
        {
            title: '产品内容',
            dataIndex: 'product',
            key: 'product',
            align:'center',
            width:'120px',
        },
        {
            title: '服务适用范围',
            dataIndex: 'apply_rank',
            key: 'apply_rank',
            align:'center',
            width:'130px',
        },
        {
            title: '产品简介(服务/检测依据)',
            dataIndex: 'detail',
            key: 'detail',
            width:'130px',
        },
        {
            title: '产品用途说明',
            dataIndex: 'description',
            key: 'description',
            width:'130px',
        },
        {
            title: '检测项目',
            dataIndex: 'test_item',
            key: 'test_item',
        },
        // {
        //     title: '样品要求',
        //     dataIndex: 'sample_request',
        //     key: 'sample_request',
        //     align:'center',
        //     width:'80px',
        // },

    ];
    const columnsTwo = [
        // {
        //     title: '质量技术服务大类代码',
        //     dataIndex: 'type_code',
        //     key: 'type_code',
        //     scopedSlots: { customRender: 'type_code' },
        //     align:'center',
        //     width:'110px',

        // },
        // {
        //     title: '质量技术服务大类名称',
        //     dataIndex: 'type_name',
        //     key: 'type_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '质量技术服务代码',
        //     dataIndex: 'quality_code',
        //     key: 'quality_code',
        //     align:'center',
        //     width:'100px',
        // },
        {
            title: "序号",
            slots: { customRender: "num" },
            align:'center',
            width:'100px',
        },
        {
            title: '质量技术服务名称',
            dataIndex: 'name',
            key: 'name',
            align:'center',
            width:'150px',
            
        },
        {
            title: '产品编码',
            dataIndex: 'product_id',
            key: 'product_id',
            align:'center',
            width:'110px',
        },
        {
            title: '行业分类',
            dataIndex: 'industry_type',
            key: 'industry_type',
            align:'center',
            width:'110px',
        },
        {
            title: '产品类别',
            dataIndex: 'product_type',
            key: 'product_type',
            align:'center',
            width:'90px',
        },
        {
            title: '产品内容',
            dataIndex: 'product',
            key: 'product',
            align:'center',
            width:'150px',
        },
        {
            title: '服务适用范围',
            dataIndex: 'apply_rank',
            key: 'apply_rank',
            align:'center',
            width:'120px',
        },
        {
            title: '产品简介(服务/检测依据)',
            dataIndex: 'detail',
            key: 'detail',
        },
        {
            title: '产品用途说明',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: '样品要求',
        //     dataIndex: 'sample_request',
        //     key: 'sample_request',
        //     align:'center',
        //     width:'200px',
        // },

    ];
    const data = ref()
    const table_data = ref()

    //轮播电脑端
    const silderSwiper = async () => {
        let postData = {
            page_id: '/' + route.params.page_id,
            second_navigation_id:  route.params.second_id,
        }
        try{
            await post('/nav/get_third_nav', postData).then((result) => {
                // console.log('我的行业', result)
                if(result.code == 1){
                    sliderList.value = result.data
                    sliderActive.value = route.params.id
                    setTimeout(() => {
                        let a = new Swiper('.swiper-container', {
                            slidesPerView: 8,
                            spaceBetween: 0,
                            slidesPerGroup: 8,
                            // loop: true,
                            loopFillGroupWithBlank: true,
                            observer:true,//修改swiper自己或子元素时，自动初始化swiper
                            observeParents:true,//修改swiper的父元素时，自动初始化swiper
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                        });
                        a.slideTo(route.params.index);    
                        let b = new Swiper('.swiper-container_two', {
                            slidesPerView: 3,
                            spaceBetween: 0,
                            slidesPerGroup: 3,
                            // loop: true,
                            loopFillGroupWithBlank: true,
                            observer:true,//修改swiper自己或子元素时，自动初始化swiper
                            observeParents:true,//修改swiper的父元素时，自动初始化swiper
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                        });
                        b.slideTo(route.params.index);
                        // silderSwiperTwo() //手机端
                    },1)
                    
                }else{
                    // console.log('请求失败')
                }
            })
            
        }catch(e) {
        
        }
        
    }
    
    //获取三级
    const getThirdNav = async (id, indextwo) => {
        let postData = {
            page_id: '/service_industry',
            second_navigation_id: id,
        }
        await post('/nav/get_third_nav', postData).then((res) => {
            // console.log('三级导航', res)
            // console.log('此时页数'+pageOne.value)
            // console.log('此时页数'+pageTwo.value)
            third_id.value = res.data[indextwo].id
            thirdList.value = res.data
            thirdIndex.value = indextwo
            getServiceDetail(res.data[indextwo].id, route.params.second_id)
        })
    }

    // const getServiceNav = async () => {
    //     try{
    //         await post('/Industry/get_service_list', {second_navigation_id: route.params.second_id}).then((res) => {
    //             // console.log('列表服务',res)
    //             list.value = res.data
    //             index.value = route.params.index
    //         })
    //     }catch(e) {
        
    //     }
    // }

    //跟换三级
    const handleChangeThird = (id, index) => {
        // console.log(id)
        third_id.value = id
        // console.log('pageOne.value：'+pageOne.value)
        // console.log('pageTwo.value：'+pageTwo.value)
        if(id==43){
            pageOne.value = 1
        }else if(id==44){
            pageTwo.value = 1
        }
        
        thirdIndex.value = index
        getServiceDetail(id, route.params.second_id)
    }

    //轮播手机端
    const silderSwiperTwo = () => {
        let b = new Swiper('.swiper-container_two', {
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerGroup: 3,
            // loop: true,
            loopFillGroupWithBlank: true,
            observer:true,//修改swiper自己或子元素时，自动初始化swiper
            observeParents:true,//修改swiper的父元素时，自动初始化swiper
        });
        // b.slideTo(route.params.index);    
    }

    //点击导航更换数据
    const handleSliderIndex = (index) => {
        // sliderActive.value = index
    }
    
    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        // console.log('更换页数的时候3级头：'+third_id.value)

        // console.log('new_page：'+new_page)
        // console.log('pageOne.value：'+pageOne.value)
        // console.log('pageTwo.value：'+pageTwo.value)
        if(third_id.value=='43' || third_id.value==43){
            pageOne.value = new_page
        }else if(third_id.value=='44' || third_id.value==44){
            pageTwo.value = new_page
        }
        
        getServiceDetail(third_id.value, route.params.second_id)
    }

    // 点击小标题
    const changeContent = (index) => {
        // console.log(index)
        title_index.value = index
    }
    const changeModal = () =>{
        const newWindow = window.open('', '_blank', 'width=600,height=400');
        newWindow.document.write(`
            <html>
            <body style="margin:0;padding:0;text-align: center;">
                <p style="text-align: center;">扫码添加微信客服咨询</p>
                <img src="https://gddqt.com//uploads/20240910/045192d6720e118f6ae0c785c4e20c96.png" alt="Your Image" style="width:90%;height:90%;object-fit:contain;" />
            </body>
            </html>
        `);
    }

        //获取详情
    const getServiceDetail = async (index, id) =>  {
        // console.log(id)
        // console.log(index)
        if(id=='51'){
            serviceDetail.value = ""
            sliderActive.value = ""
            // console.log(id)
            // console.log('pageOne.value:'+pageOne.value)
            // console.log('pageOne.value:'+pageTwo.value)
            // 清单
            let postData=''
            if(index==43 || index=='43'){
                postData = {
                    page: pageOne.value,
                    third_navigation_id:index
                }

            }else if(index==44 || index=='44'){
                postData = {
                    page: pageTwo.value,
                    third_navigation_id:index
                }
            }
        
            try{
                await post('/industry/get_repertoire_list', postData).then((res) => {
                    // console.log('清单列表', res)
                    data.value = res.data.data
                    last_page.value = res.data.total
                })
                
            }catch(e) {
            
            }
        }else{
            let postData = {
                    // page_id: '/' + route.params.page_id,
                    id: index,
                    second_navigation_id:  id,
                }
            
            try{
                const result = await post('/industry/get_service_industry', postData)
                // console.log('我的行业详情', result)
                if(result.code == 1){
                    serviceDetail.value = result.data
                    sliderActive.value = route.params.id
                    table_data.value = result.data.table_content
                    // console.log(table_data.value.length)
                    setTimeout(() => {
                        serviceSwiper()
                        serviceSwiperTwo()
                    },500)
                    
                }else{
                    // console.log('请求失败')
                }
            }catch(e) {
            
            }
        }
    }

    watchEffect(() => {
        if(route.params.id) {
            third_id.value = route.params.id
            title_index.value = 1
            silderSwiper()
            getThirdNav(route.params.second_id, route.params.index)
            getServiceDetail(route.params.id, route.params.second_id)
        }
    })

    // 电脑端
    const serviceSwiper = () => {
        // new Swiper('.service-swiper-container', {
        //     slidesPerView: 3,
        //     spaceBetween: 30,
        //     loop: true,
        //     navigation: {
        //         nextEl: '.button-next',
        //         prevEl: '.button-prev',
        //     },
        // });
    }

    // 手机端
    const serviceSwiperTwo = () => {
        new Swiper('.service-swiper-container-two', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: true,
            autoplay: 3000,
            navigation: {
                nextEl: '.button-next',
                prevEl: '.button-prev',
            },
        });
    }

    return { sliderList, silderSwiper, silderSwiperTwo, sliderActive, handleSliderIndex, serviceDetail, getServiceDetail, serviceSwiper, serviceSwiperTwo, third_id , columnsOne,columnsTwo, pageOne, last_page, handleChangePage, data, handleChangeThird, changeContent, 
            title_index, table_data, getThirdNav, thirdList, thirdIndex, changeModal }
}

//轮播图函数
const bannerEffect = (route) => {
    const banner = ref()

    const getBanner = async () =>  {
        await post('/carousel/get_carousel', {page_id: '/service_industry', second_navigation_id:route.params.second_id}).then((res) => {
            banner.value = res.data.images
        })
    }

    watchEffect(() => {
        if(route.params.second_id) {
            getBanner()
        }
    })

    return { getBanner, banner }


}


export default {
    name: 'Industry',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //滑块处理函数
        const { 
            sliderList, 
            silderSwiperTwo,
            sliderActive, 
            handleSliderIndex ,
            serviceDetail, 
            silderSwiper,
            getServiceDetail,
            serviceSwiper, 
            serviceSwiperTwo,
            third_id,
            columnsTwo, handleChangePage,handleChangeThird,
            changeContent, title_index, table_data, changeModal,
            columnsOne,
            data, pageOne,pageTwo, last_page, getThirdNav, thirdList, thirdIndex
        } = sliderEffect(route)

        const { getBanner, banner } = bannerEffect(route)

        //服务项目处理函数
        // const { serviceDetail, getServiceDetail, serviceSwiper, serviceSwiperTwo } = serviceEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onBeforeMount(() => {
            // document.querySelector('body').setAttribute('style', 'background-color:#F8F8F8')
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", function () {
                handleScroll();
            })
        })

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        // console.log('aaaaaaaaaaaaaaaa', route.params.second_id)

        const second_id = ref()

        

        watchEffect(() => {
            second_id.value = route.params.second_id
        })

        getBanner()
        silderSwiper()
        silderSwiperTwo()
        getServiceDetail(route.params.id, route.params.second_id)
        serviceSwiper()
        serviceSwiperTwo()

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        const { imgUrl }  = api
        
        return { 
            isShow,
            header, 
            sliderList,
            serviceDetail, 
            sliderActive, 
            handleSliderIndex,
            banner,
            imgUrl,
            second_id,
            third_id, handleChangePage, columnsTwo, handleChangeThird,
            changeContent, title_index, table_data, changeModal,
            columnsOne,
            data, pageOne,pageTwo, last_page, getThirdNav, thirdList, thirdIndex
        }

    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        /* margin: 0 auto; */
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
/* 电脑端滑块 */
.slider_box{
    background: #fff;
    @media screen and (max-width: 1200px){
        display: none;
    }
    .slider{
        position: relative;
        .swiper-container {
            width: 80%;
            height: 100%;
            margin:0 auto;
            .swiper-wrapper{
                .swiper-slide {
                    /* width: 165px; */
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 5px;
                    /* border-right: 1px solid#bbbbbb70;
                    border-left: 1px solid#bbbbbb70; */
                    cursor: pointer;
                    transition: .3s all;
                    &:hover{
                        background-color: #2F509E;
                        .list_title{
                            color: #fff;
                        }
                        .list_img{
                            display: none;
                        }
                        .select_img{
                            display: block;
                        }
                    }
                    .list_img{
                        width: 40px;
                        height: 40px;
                        object-fit: scale-down;
                    }
                    .select_img{
                        display: none;
                    }
                    .list_title{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                        text-align: center;
                        margin-top: 10px;
                    }
                    .right_line{
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 1px;
                        background: #bbbbbb70;
                    }
                }
                .swiper-slide_active{
                    background: #2F509E;
                    .list_title{
                        color: #fff;
                    }
                }
            }
        }
        .swiper-button-next{
            right: 90px;
            left: auto;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            background: #fff;
            
        }
        .swiper-button-black{
            --swiper-navigation-color:  rgba(119, 119, 119, 100);;
        }
        .swiper-button-prev{
            right: auto;
            left: 90px;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            background: #fff;
        }
    }
}
/* 手机端滑块 */
.slider_box_two{
    background: #fff;
    display: none;
    @media screen and (max-width: 1200px){
        display: block;
    }
    .slider{
        position: relative;
        .swiper-container_two {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .swiper-wrapper{
                .swiper-slide {
                    /* width: 165px; */
                    height: 150px;
                    padding: 0 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    /* border-right: 1px solid#bbbbbb70;
                    border-left: 1px solid#bbbbbb70; */
                    cursor: pointer;
                    transition: .3s all;
                    position: relative;
                    @media screen and (max-width: 900px){
                        height: .9rem;
                    }
                    .list_img{
                        width: 80px;
                        height: 80px;
                        object-fit: scale-down;
                        @media screen and (max-width: 900px){
                            width: .4rem;
                            height: .4rem;
                        }
                    }
                    .list_title{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                            margin-top: 10px;
                        }
                    }
                    .right_line{
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 1px;
                        background: #bbbbbb70;
                    }
                }
                .swiper-slide_active{
                    background: #2F509E;
                    .list_title{
                        color: #fff;
                    }
                }
            }
        }
        .swiper-button-next{
            right: 0px;
            left: auto;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            /* background: #fff; */
            z-index: 99;
            &:after{
                font-size: 24px;
            }
        }
        .swiper-button-black{
            --swiper-navigation-color:  rgba(119, 119, 119, 100);;
        }
        .swiper-button-prev{
            right: auto;
            left: 00px;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            /* background: #fff; */
            z-index: 99;
            &:after{
                font-size: 24px;
            }
        }
    }
}
/* 内容 */
.content_box{
    margin-top: 20px;
    background: #fff;
    .content{
        display: flex;
        padding: 30px 0 0 0;
        @media screen and (max-width: 1200px){
            display: block;
        }
        .img{
            width: 30%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        .text{
            width: 60%;
            padding: 0 10%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 1200px){
                width: auto;
                padding: 30px 30px;
            }
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 20px;
                font-weight: bold;
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 30px;
                line-height: 24px;
                text-align: justify;
                 @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            }
            .online{
                transition: all 0.36s ease;
                display: inline-block;
                color: white;
                font-size: 14px;
                padding: 10px 15px;
                background-color: #2F509E;
                margin-bottom: 20px;
                width: max-content;
                cursor: pointer;
                border-radius: 5px;
                margin-top: 15px;
            }
        }
    }
}
/* 详情 */
.detail_title{
    display: flex;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #e6e6e6;
    @media screen and (max-width: 1200px){
        padding-left: 30px;
    }
    p{
        color: #a8a9ac;
        font-size: 20px;
        font-weight: bold;
        margin-right: 40px;
        cursor: pointer;
    }
    .active{
        color: #305aa8;
    }

    /* border-bottom: 1px solid linear-gradient(to bottom right, red, yellow); */
    /* background-image: linear-gradient(to bottom right, red, yellow); */
}
.detail_box{
    margin-top: 40px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
}
.detail{
    /* display: flex; */
    flex-wrap: wrap;
    @media screen and (max-width: 900px){
        display: block;
    }
    .list{
        width: 80%;
        margin-right: 4%;
        margin-bottom: 50px;
        overflow-x: auto;
        &:nth-child(2n){
            margin-right: 0%;
        }
        @media screen and (max-width: 900px){
            width: 100%;
            margin-right: 0%;
        }
        .title{
            color: rgba(47, 80, 158, 100);
            font-size: 18px;
            font-weight: 600;
            /* &:after{
                content: "  ";
                width: 100%;
                height: 1px;
                display: block;
                background-color: #e2e2e2;
            } */
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
        }
        .desc{
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            margin-top: 14px;
            line-height: 24px;
            @media screen and (max-width: 900px){
                font-size: .14rem;
            }
        }
        .imgs{
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            .img{
                width: 21%;
                object-fit: cover;
                margin-right: 5%;
                margin-bottom: 15px;
                &:nth-child(4n){
                    margin-right: 0%;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
}
/* 服务 */
.service_box{
    background: #fff;
    /* margin-top: 20px; */
    .service{
        .top{
            padding: 0px 60px 0 60px;
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }
            .line{
                width: 100%;
                height: 2px;
                background: rgba(187, 187, 187, 100);
                margin-top: 20px;
            }
        }
        /* 电脑端 */
        .under{
            margin-top: 40px;
            @media screen and (max-width: 1200px){
                /* display: none; */
                padding: 0 30px;
            }
            .service-swiper-container {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                /* padding-bottom: 110px; */
                .swiper-slide {
                    width: 31%;
                    margin-right: 3%;
                    margin-bottom: 30px;
                    text-align: center;
                    font-size: 18px;
                    background: #F4F4F4;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    transition: .3s all;
                    &:nth-child(3n){
                        margin-right: 0%;
                    }
                    @media screen and (max-width: 900px){
                        width: 48%;
                        margin-right: 4%;
                        &:nth-child(3n){
                            margin-right: 4%;
                        }
                        &:nth-child(2n){
                            margin-right: 0%;
                        }
                    }
                    @media screen and (max-width: 900px){
                        width: 100%;
                        margin-right: 0%;
                        &:nth-child(3n){
                            margin-right: 0%;
                        }
                        &:nth-child(2n){
                            margin-right: 0%;
                        }
                    }
                    &:hover{
                        background: rgba(48, 90, 168, 100);
                        .title,.desc,.more{
                            color: #fff;
                        }
                        .img{
                            img{
                                transform: scale(1.2);
                            }
                        }
                    }
                    .img{
                        width: 100%;
                        height: 250px;
                        overflow: hidden;
                        img{
                            transition: .3s all;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .title{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                        font-weight: 600;
                        margin-top: 10px;
                    }
                    .desc{
                        color: rgba(16, 16, 16, 100);
                        font-size: 14px;
                        padding: 0 30px;
                        margin-top: 10px;
                        text-align: left;
                        line-height: 22px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    .more{
                        color: rgba(16, 16, 16, 100);
                        font-size: 14px;
                        border: 1px solid rgba(187, 187, 187, 100);
                        padding: 8px 15px;
                        margin: 30px auto 20px auto;
                        width: max-content;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                        }
                    }
                }
                .button-next{
                    right: 45%;
                    left: auto;
                    top: 90%;
                    position: absolute;
                    cursor: pointer;
                    transform: rotate(180deg);
                    font-size: 24px;
                }
                .button-prev{
                    left: 45%;
                    right: auto;
                    top: 90%;
                    position: absolute;
                    cursor: pointer;
                    font-size: 24px;
                }
            }
        }
        /* 手机端 */
        .under_two{
            margin-top: 40px;
            display: none;
            @media screen and (max-width: 1200px){
                /* display: block; */
            }
            .service-swiper-container-two {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
                padding-bottom: 50px;
                .swiper-slide {
                    text-align: center;
                    font-size: 18px;
                    background: #F4F4F4;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    transition: .3s all;
                    &:hover{
                        background: rgba(48, 90, 168, 100);
                        .title,.desc,.more{
                            color: #fff;
                        }
                        .img{
                            img{
                                transform: scale(1.2);
                            }
                        }
                    }
                    .img{
                        width: 100%;
                        height: 200px;
                        overflow: hidden;
                        img{
                            transition: .3s all;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .title{
                        color: rgba(16, 16, 16, 100);
                        font-size: 20px;
                        font-weight: 600;
                        margin-top: 10px;
                    }
                    .desc{
                        color: rgba(16, 16, 16, 100);
                        font-size: 14px;
                        padding: 0 30px;
                        margin-top: 10px;
                        text-align: left;
                        line-height: 22px;
                        margin-bottom: 20px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .more{
                        color: rgba(16, 16, 16, 100);
                        font-size: 1px;
                        border: 1px solid rgba(187, 187, 187, 100);
                        padding: 8px 15px;
                        margin: 30px auto 20px auto;
                        width: max-content;
                    }
                }
                .button-next{
                    right: 45%;
                    left: auto;
                    top: 90%;
                    position: absolute;
                    cursor: pointer;
                    transform: rotate(180deg);
                    font-size: 24px;
                }
                .button-prev{
                    left: 45%;
                    right: auto;
                    top: 90%;
                    position: absolute;
                    cursor: pointer;
                    font-size: 24px;
                }
            }
        }
    }
}
/* 服务流程 */
.process_box{
    background: #fff;
    margin-top: 20px;
    .process{
        .top{
            padding: 0px 60px 0 60px;
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }
            .line{
                width: 100%;
                height: 2px;
                background: rgba(187, 187, 187, 100);
                margin-top: 20px;
            }
        }
        .under{
            margin-top: 15px;
            img{
                width: 50%;
                margin: 0 auto;
                display: block;
            }
        }
    }
}
.bottom_line_box{
    padding: 50px 0;
    background: #fff;
    .bottom_line{
        width: 100%;
        height: 2px;
        background: rgba(187, 187, 187, 100);
    }
}

.third{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .text{
        width: 48%;
        text-align: center;
        cursor: pointer;
        p{
            display: block;
            font-size: 16px;
            color: #666666;
            padding: 12px 0;
            border: 1px solid #dcdcdc;
            margin: 10px 0;
        }
        .active{
            color: #1f2c5c;
            border: 1px solid #1f2c5c;
        }
    }
}
.third_nav{
    @media screen and (max-width: 1200px){
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        .text{
            width: 48%;
            text-align: center;
            cursor: pointer;
            p{
                display: block;
                font-size: 16px;
                color: #666666;
                padding: 12px 20px;
                border: 1px solid #dcdcdc;
                margin: 10px 0;
            }
            .active{
                color: #1f2c5c;
                border: 1px solid #1f2c5c;
            }
        }
    }
}

.pagination{
    @media screen and (max-width: 1200px){
        display: block;
        text-align: center;
        padding-top: 50px;
    }
}

</style>